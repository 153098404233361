import React from "react"
import styles from "./blockImgCenterText.module.css"
const BlockImgCenterText = ({ bgColor, data }) => {
  const {
    title,
    img: {
      uri: { url: image },
    },
  } = data
  return (
    <section
      className={styles.blockImgCenterText}
      style={{
        backgroundImage: `url(https://drupal.smartempo.com${image})`,
      }}
    >
      <div className={styles.blockImgCenterText__imgText}>
        <div className={styles.blockImgCenterText__imgText__text}>
          <h1>{title}</h1>
        </div>
      </div>
    </section>
  )
}
export default BlockImgCenterText
