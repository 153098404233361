import React from "react"
import styles from "./blockTitleTextFull.module.css"

const BlockTitleTextFull = ({ bgColor, data }) => {
  const { title, text } = data
  return (
    <section className={styles.blockTitleTextFull}>
      <div className={styles.blockTitleTextFull__titleText}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </section>
  )
}
export default BlockTitleTextFull
