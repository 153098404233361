import React from "react"
import styles from "./blockImgTitleTextCenter.module.css"
const BlockImgTitleTextCenter = ({ bgColor, data }) => {
  const {
    title,
    text,
    img: {
      uri: { url: image },
    },
  } = data
  return (
    <section className={styles.blockImgTitleTextCenter}>
      <div className={styles.blockImgTitleTextCenter__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt="" />
      </div>
      <div className={styles.blockImgTitleTextCenter__titleText}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </section>
  )
}
export default BlockImgTitleTextCenter
