import { useStaticQuery, graphql } from "gatsby"

export const useTeamMembers = () => {
  const { allNodeTeamMembers } = useStaticQuery(
    graphql`
      query TeamMembers {
        allNodeTeamMembers {
          edges {
            node {
              field_person_name
              field_person_description
              relationships {
                field_person_name_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeTeamMembers.edges
}
