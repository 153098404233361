import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockImgCenterText from "../components/BlockImgCenterText/BlockImgCenterText"
import BlockTitleTextFull from "../components/BlockTitleTextFull/BlockTitleTextFull"
import BlockImgTitleTextRight from "../components/BlockImgTitleTextRight/BlockImgTitleTextRight"
import BlockImgTitleTextCenter from "../components/BlockImgTitleTextCenter/BlockImgTitleTextCenter"
import BlockTwoElements from "../components/BlockTwoElements/BlockTwoElements"
import BlockThreeColumns from "../components/BlockThreeColumns/BlockThreeColumns"
import BlockBackgroundText from "../components/BlockBackgroundText/BlockBackgroundText"

import Footer from "../components/Footer/Footer"

import { useWhyStpo } from "../hooks/use-whySmartempo"
import { useTeamMembers } from "../hooks/use-teamMembers"

const WhySmartempo = () => {
  const whyData = useWhyStpo()
  const teamMembersData = useTeamMembers()

  console.log(teamMembersData)

  const imgCenterTextData = {
    title: whyData.field_imgcentertext_title_,
    img: whyData.relationships.field_imgcentertext_img_,
  }
  const titleTextFullData = {
    title: whyData.field_titletextfull_title_,
    text: whyData.field_titletextfull_text_,
  }
  const imgTitleTextRightData = {
    title: whyData.field_imgtitletextright_title_,
    text: whyData.field_imgtitletextright_text_,
    img: whyData.relationships.field_imgtitletextright_img_,
  }
  const imgTitleTextCenterData = {
    title: whyData.field_imgtitletextcenter_title_,
    text: whyData.field_imgtitletextcenter_text_,
    img: whyData.relationships.field_imgtitletextcenter_img_,
  }
  const twoElementsData = {
    titleOne: whyData.field_two_elements_1_title_,
    textOne: whyData.field_two_elements_1_text_,
    imgOne: whyData.relationships.field_two_elements_1_img_,
    titleTwo: whyData.field_two_elements_2_title_,
    textTwo: whyData.field_two_elements_2_text_,
    imgTwo: whyData.relationships.field_two_elements_2_img_,
  }
  const threeColumnsData = {
    titleOne: whyData.field_two_elements_1_title_,
    textOne: whyData.field_two_elements_1_text_,
    imgOne: whyData.relationships.field_two_elements_1_img_,
    titleTwo: whyData.field_two_elements_2_title_,
    textTwo: whyData.field_two_elements_2_text_,
    imgTwo: whyData.relationships.field_two_elements_2_img_,
    titleThree: whyData.field_two_elements_3_title_,
    textThree: whyData.field_two_elements_3_text_,
    imgThree: whyData.relationships.field_two_elements_3_img_,
  }

  // const titleBgImgLeftTextData = {
  //   title: blogData.field_titlebgimglefttext_title_,
  //   text: blogData.field_titlebgimglefttext_text_,
  //   img: blogData.relationships.field_titlebgimglefttext_img,
  // }

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockImgCenterText data={imgCenterTextData} />
        <BlockTitleTextFull data={titleTextFullData} />
        <BlockImgTitleTextRight data={imgTitleTextRightData} />
        <BlockImgTitleTextCenter data={imgTitleTextCenterData} />
        <BlockTwoElements data={twoElementsData} />
        <BlockThreeColumns data={teamMembersData} />
        {/* <BlockBackgroundText /> */}
        <Footer />
      </div>
    </div>
  )
}

export default WhySmartempo
