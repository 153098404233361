import { useStaticQuery, graphql } from "gatsby"

export const useWhyStpo = () => {
  const { allNodeWhysmartempo } = useStaticQuery(
    graphql`
      query WhyStpoPage {
        allNodeWhysmartempo {
          edges {
            node {
              field_imgcentertext_title_
              field_imgtitletextcenter_text_
              field_imgtitletextcenter_title_
              field_imgtitletextright_text_
              field_imgtitletextright_title_
              field_titletextfull_title_
              field_titletextfull_text_
              field_two_elements_1_text_
              field_two_elements_1_title_
              field_two_elements_2_text_
              field_two_elements_2_title_
              relationships {
                field_imgcentertext_img_ {
                  uri {
                    url
                  }
                }
                field_imgtitletextcenter_img_ {
                  uri {
                    url
                  }
                }
                field_two_elements_1_img_ {
                  uri {
                    url
                  }
                }
                field_two_elements_2_img_ {
                  uri {
                    url
                  }
                }
                field_imgtitletextright_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeWhysmartempo.edges[0].node
}
