import React from "react"
import styles from "./blockTwoElements.module.css"
const BlockTwoElements = ({ bgColor, data }) => {
  const {
    titleOne,
    textOne,
    titleTwo,
    textTwo,
    imgOne: {
      uri: { url: image1 },
    },
    imgTwo: {
      uri: { url: image2 },
    },
  } = data
  return (
    <section
      className={styles.blockTwoElements}
      style={{
        backgroundColor: `#006791`,
      }}
    >
      <div className={`${styles.blockTwoElements__item}`}>
        <div className={styles.blockTwoElements__item__img}>
          <img src={`https://drupal.smartempo.com${image1}`} alt={titleOne} />
        </div>
        <div className={styles.blockTwoElements__item__titleText}>
          <h3>{titleOne}</h3>
          <p>{textOne}</p>
        </div>
      </div>
      <div
        className={`${styles.blockTwoElements__item}`}
        style={{
          backgroundColor: `#84c9fa`,
        }}
      >
        <div className={styles.blockTwoElements__item__img}>
          <img src={`https://drupal.smartempo.com${image2}`} alt={titleTwo} />
        </div>
        <div className={styles.blockTwoElements__item__titleText}>
          <h3>{titleTwo}</h3>
          <p>{textTwo}</p>
        </div>
      </div>
    </section>
  )
}
export default BlockTwoElements
