import React from "react"
import styles from "./blockThreeColumns.module.css"

const TeamMember = ({ person }) => {
  const {
    name,
    description,
    img: {
      uri: { url: image },
    },
  } = person
  return (
    <div className={styles.blockThreeColumns__item}>
      <div className={styles.blockThreeColumns__item__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={name} />
      </div>
      <div className={styles.blockThreeColumns__titleText}>
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}

const BlockThreeColumns = ({ bgColor, data }) => {
  return (
    <section className={styles.blockThreeColumns}>
      {data.map((teamMember, idx) => {
        const personData = {
          name: teamMember.node.field_person_name,
          description: teamMember.node.field_person_description,
          img: teamMember.node.relationships.field_person_name_img_,
        }
        const pid = `p-${idx}`
        return <TeamMember key={pid} person={personData} />
      })}
    </section>
  )
}
export default BlockThreeColumns
